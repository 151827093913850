@charset "utf-8";
/* CSS Document */

body {
  font-size: 14px;
  font-weight: normal;
  background: #ffffff;
  color: #000000;
  min-height: 100vh;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック",
    "MS Gothic", "Noto Sans CJK JP", TakaoPGothic, sans-serif;
}

@font-face {
  font-family: "Hiragino Kaku Gothic Pro";
  src: url(../Fonts/Hiragino\ Kaku\ Gothic\ Pro\ W3.otf);
}

@font-face {
  font-family: "HiraginoPron W3";
  src: url(../Fonts/Hiragino\ Kaku\ Gothic\ ProN\ W3.otf);
}

@font-face {
  font-family: "HiraginoPron W6";
  src: url(../Fonts/Hiragino\ Kaku\ Gothic\ Pro\ W6.otf);
}

@font-face {
  font-family: "SF pro text";
  src: url(../Fonts/SF-Pro-Text-Regular.otf);
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
  outline: 0px auto -webkit-focus-ring-color !important;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
}

button:focus {
  outline: none !important;
}

* {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.flex-fill {
  flex: 1 1 auto;
}

.rounded-lg {
  border-radius: 1rem;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.container-padding {
  padding-left: 5.5rem;
}

.nav-hd {
  width: 100%;
}

.avthr-box h5,
p {
  color: #000;
  font-size: 16px;
}

.avthr-box {
  background-color: #fff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  border-radius: 7px;
}

.avthr-box img {
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px 0px;
}

.form-login input[type="text"],
textarea {
  outline: none;
  box-shadow: none !important;
  border-bottom: 1px solid #ccc !important;
}

.form-control:focus {
  border-color: #cccccc;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.update-btn {
  color: #000;
  background-color: #47dab3;
}

.update-btn:hover {
  background-color: #3cd2aa;
  color: #000;
}

.list-p {
  font-size: 16px;
  color: #000 !important;
}

.form-control {
  font-size: 14px;
}

.login-btn {
  color: #000;
  background-color: #47dab3;
}

.reg-text {
  font-size: 14px;
  color: #000;
}

.reg-text:hover {
  color: #666;
  text-decoration: none;
}

/*staff list*/
.nav-menu a {
  color: #666;
}

.nav-stff .nav-link.active,
.nav-pills .show>.nav-link {
  color: #1fb9df;
  border-bottom: 1px solid #1fb9df;
  background-color: #fff;
  border-radius: 0;
}

.tab-bg {
  box-shadow: 0px 0px 4px 0px #ccc;
  background-color: #fff;
}

.font-staf {
  font-size: 15px;
}

.personal-info {
  background-color: #1fb9df;
  border-radius: 25px;
  color: #fff !important;
  padding: 7px;
  cursor: pointer;
  width: 35px;
  border: none;
}

/*.table td,
.table th {
  vertical-align: top !important;

}*/

.close-font {
  font-size: 16px;
  color: #a1a2a2;
  cursor: pointer;
}

.rightside-text {
  color: #8c8989;
}

.img-right {
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px 0px;
}

/*staff right side*/
.rightSide {
  position: fixed;
  width: 400px;
  background: #fff;
  right: 0;
  top: 0;
  height: 100%;
  display: none;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  z-index: 1030;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.w3-animate-right {
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

/*Card list*/

.img-type {
  border-radius: 30px;
  width: 60px;
  height: 60px;
}

/*user list*/
.sub-help a {
  padding-top: 2px !important;
}

.csv-btn {
  color: #000;
  background-color: #47dab3;
}

.user-block {
  background-color: #ea8012;
  border-radius: 25px;
  color: #fff;
  padding: 7px;
  cursor: pointer;
  width: 35px;
  border: none;
  margin-top: 5px;
}

.user-unblock {
  background-color: #49c165;
  border-radius: 25px;
  color: #fff;
  padding: 7px;
  cursor: pointer;
  width: 35px;
  border: none;
  margin-top: 5px;
}

.user-delete {
  background-color: #ff5a5a;
  border-radius: 25px;
  color: #fff;
  padding: 7px;
  cursor: pointer;
  width: 35px;
  border: none;
  margin-top: 5px;
}

.delete-cir {
  font-size: 2.5rem;
  color: #f1a969;
  width: 90px;
  height: 87px;
  border-radius: 45px;
  border: 2px solid #f1a969;
}

.action-list li {
  float: left;
  list-style: none;
  margin-right: 10px;
}

.browser-scv {
  position: relative;
  overflow: hidden;
  color: #1fb9df;
  background-color: #ffffff;
  border: 1px solid #1fb9df !important;
  cursor: pointer;
}

.browser-scv input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.upload-bg {
  border: 2px dashed #bbbaba;
}

.upload-img {
  opacity: 0.5;
}

.drop-upload {
  font-size: 24px;
  color: #b5b3b3;
}

.modal-bgg {
  border-radius: 0;
  border: none;
}

.csv-save {
  color: #000;
  background-color: #47dab3;
}

.upload-invoice {
  border: 1px solid #f94e4e !important;
  color: #f94e4e;
  background: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 38px;
}

.submit-upload {
  color: #fff;
  background-color: #1ca1cd;
  cursor: pointer;
}

.add-user-btn {
  border: 1px solid #47dab3 !important;
  color: #47dab3;
  background-color: #fff;
}

.refund-btn {
  background-color: #fff;
  border: 1px solid #f94e4e !important;
  color: #f94e4e;
  width: 100px;
}

.invoic-btn {
  background-color: #fff;
  border: 1px solid #f94e4e !important;
  color: #f94e4e;
}

.invoi {
  background-color: #e9e9e9;
}

.modal-xll {
  max-width: 90%;
}

.card-type {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.cards-t-1 {
  background-color: #d7f4fb;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  padding: 15px;
  border-radius: 7px;
  display: flex;
}

.cards-t-1 h6 {
  color: #0f7088;
  font-size: 20px;
}

.cards-t-1 p {
  color: #0f7088;
}

.cards-t-1 i {
  color: #0f7088;
  font-size: 34px;
}

.cards-t-2 {
  background-color: #fbf3d7;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  padding: 15px;
  border-radius: 7px;
}

.cards-t-2 h6 {
  color: #9a7b0d;
  font-size: 20px;
}

.cards-t-2 p {
  color: #9a7b0d;
}

.cards-t-2 i {
  color: #9a7b0d;
  font-size: 34px;
}

.cards-t-3 {
  background-color: #e5d7fb;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  padding: 15px;
  border-radius: 7px;
}

.cards-t-3 h6 {
  color: #3d0d88;
  font-size: 20px;
}

.cards-t-3 p {
  color: #3d0d88;
}

.cards-t-3 i {
  color: #3d0d88;
  font-size: 34px;
}

.cards-t-4 {
  background-color: #fbd7d7;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  padding: 15px;
  border-radius: 7px;
}

.cards-t-4 h6 {
  color: #af1515;
  font-size: 20px;
}

.cards-t-4 p {
  color: #af1515;
}

.cards-t-4 i {
  color: #af1515;
  font-size: 34px;
}

.div-payment {
  cursor: pointer;
  text-align: left;
}

.nav-menu a {
  color: #666;
}

.nav-stff .nav-link.active,
.nav-pills .show>.nav-link {
  color: #1fb9df;
  border-bottom: 1px solid #1fb9df;
  background-color: #fff;
  border-radius: 0;
}

.tab-bg {
  box-shadow: 0px 0px 4px 0px #ccc;
  background-color: #fff;
}

.font-staf {
  font-size: 15px;
}

/*email-page*/
.comp-arrow {
  color: #000;
  font-size: 16px;
}

.compose-btn {
  background-color: #1fb9df;
  color: #fff;
}

.mail-bg {
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e6e6;
  border-top: 1px solid #e8e6e6;
  background-color: #fff;
}

.mail-bg:hover {
  background-color: #f5f3f3;
  cursor: pointer;
}

.email-link {
  color: #000;
}

.email-a {
  color: #000;
}

.email-a:hover {
  color: #000 !important;
}

.email-top {
  border-top: 1px solid #ccc;
}

.detail-mail {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #e6e6e6;
}

.font-email {
  font-size: 14px;
  color: #666;
}

.head-email {
  font-size: 24px;
  color: #000;
}

.font-fas {
  font-size: 8px;
}

.detail-mail p {
  color: #666;
  font-size: 16px;
}

.invoice-p {
  cursor: pointer;
}

.modal-wid {
  max-width: 960px;
}

.invoice-sub {
  font-size: 14px;
  color: #8a8989;
  text-align: left;
}

.btn-invoice {
  color: #000;
  background-color: #47dab3;
}

.replay-btn {
  background-color: #1fb9df;
  color: #fff;
}

/**userlist**/

.list-td {
  color: #1ca1cd;
  font-size: 14px;
}

.clear-btn {
  background-color: #1fb9df;
  color: #fff;
}

.mt50 {
  margin-top: 50px;
}

.normal-text {
  font-size: 16px;
}

.filter-collapse {
  position: absolute;
  z-index: 999;
  top: 45px;
}

.filter-card {
  min-width: 300px;
  padding: 0px;
}

.border-none {
  border: none !important;
  border-bottom: 1px solid #dfdfdf !important;
}

/*****end******/

/**payment page**/

.payment-bg {
  background-color: #ececec;
}

.payment-bg h6 {
  color: #666;
}

.card-br {
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffff;
}

.checkout {
  background-color: #f94e4e;
  color: #fff;
}

.icon-tick {
  color: #50a856;
  font-size: 3rem !important;
  border: 4px solid #50a856;
  border-radius: 53px;
  width: 100px;
  height: 100px;
}

.success-bg h5 {
  color: #4fa755;
  font-weight: 600;
}

.success-bg p {
  color: #878787;
}

.btn-home {
  border: 1px solid #1fb9df !important;
  color: #1fb9df;
  background-color: transparent;
}

.btn-home:hover {
  background-color: #1fb9df;
  color: #fff;
}

.success-max {
  width: 100%;
  background-color: #fff;
  max-width: 600px;
  padding: 15px;
  margin: auto;
  box-shadow: 0px 1px 9px 0px #ccc;
}

.white_bgs {
  background-color: #fff;
}

form {
  padding-top: 10px;
  font-size: 14px;
}

.card-title {
  font-weight: 300;
}

.btn {
  font-size: 14px;
  border: none;
}

.footer-side {
  position: absolute;
  bottom: 0;

  width: 100%;
}

.side-user {
  padding: 0px !important;
}

.brand-btn {
  border: none;
  background-color: #fff !important;
}

.brand-btn:hover {
  background-color: none !important;
  color: #1fb7de;
}

.footer_menu ul li a {
  color: #3f3f3f;
}

.footer_menu ul li a:hover {
  color: #d14b4b;
  text-decoration: none;
}

.copyright {
  font-size: 12px;
  color: #9c9b9b;
}

.top_bg {
  background: #1fb9df;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.bg-bottom-grey {
  background: #cccccc;
}

.mt78 {
  margin-top: 78px;
}

.form-login input[type="text"],
textarea {
  outline: none;
  box-shadow: none !important;
  border-bottom: 1px solid #ccc !important;
}

.form-control:focus {
  border-color: #cccccc;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-head i {
  font-size: 1.6em;
  float: right;
}

.accordion-head>.collapsed>i:before {
  content: "\f105";
}

/**Tutorial page**/
.book-font {
  font-size: 4rem;
  color: #47dab3;
  border-radius: 68px;
  width: 140px;
  height: 140px;
  padding-top: 2.3rem;
  border: 4px solid #47dab3;
}

.tutor-p {
  color: #666;
  font-size: 14px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.box-tutorial {
  background-color: #fff;
}

.btn-download {
  border: 1px solid #f94e4e !important;
  color: #f94e4e;
  background: #fff;
}

.hed-terms {
  padding-left: 2rem;
}

.goto-h {
  text-decoration: none;
}

.goto-h a:hover {
  text-decoration: none;
  color: #fff !important;
}

/********************************form section******************/
.form-login .input-field {
  position: relative;
  width: 100%;
  height: 60px;
  line-height: 44px;
}

.form-login .input-field label {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  color: #47494a;
  transition: 0.2s all;
  cursor: text;
  font-size: 16px;
  text-align: start;
  font-weight: 400;
}

.form-login .input-field input {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #d3d3d3;
  box-shadow: none;
  color: #111;
}

.form-login .input-field input:invalid {
  outline: 0;
  color: #ff2300;
  border-color: #ff2300;
}

.form-login .input-field input:focus,
.form-login .input-field input:valid {
  border-color: #866e6d;
}

.form-login .input-field input:focus~label,
.form-login .input-field input:valid~label {
  font-size: 13px;
  top: -24px;
  color: #7a7c7d;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #0062cc !important;
  background-color: #0d57a6 !important;
}

.custom-control-label {
  cursor: pointer;
}

.login-input {
  box-shadow: none !important;
  border-radius: 0;
  background-color: transparent;
}

/****************************form section end***************/

.logo {
  width: 180px;
}

.btn-login {
  background-color: #1fb9df;
  border: none;
  margin-top: 1.5rem;
}

.btn-login:hover {
  background-color: #16a9ce;
}

.btn-login:focus {
  background-color: #16a9ce !important;
  box-shadow: none !important;
}

.form-bg {
  background-color: #fff;
  padding: 50px 23px;
  box-shadow: 0px 2px 7px 0px #ccc;
  border-radius: 10px;
}

.form-bg-version2 {
  background-color: #fff;
  padding: 14px 23px 40px 23px;
  border-radius: 10px;
}

.or {
  color: #686d71;
}

/**Register Page**/

.img-bg {
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #f9f9f9;
}

.register-bg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.form-padding {
  width: 100%;
  max-width: 550px;
  padding: 15px;
  margin: auto;
}

.logo-img {
  position: absolute;
  top: 5rem;
  left: 6rem;
}

.logo-img-text {
  position: absolute;
  top: 15rem;
  left: 16px;
  text-align: center;
  padding: 15px;
}

.logo-img-text p {
  color: #fff;
  font-size: 20px;
}

.pr-cl {
  color: #666;
}

.store-list {
  background-color: #efefef;
  border-bottom: 1px solid #dfdfdf;
}

/*Corporate administer list page*/
.cor_address {
  color: #606060;
}

.cor_center {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 100%;
}

.primebg {
  background: #1fb9df;
  color: #ffffff;
}

.primebtn {
  color: #000;
  background-color: #47dab3;
}

.table_brder {
  border: 1px solid #dee2e6;
}

.tbl_head {
  font-size: 16px;
  color: #686d70;
}

.sub-form {
  background-color: #efefef;
  max-height: 130px;
  overflow-y: auto;
}

.add-btn {
  background-color: #1fb7de;
  color: #fff;
}

table tr td {
  background: #ffffff;
}

.btn-ornage {
  background: #f99964;
  color: #ffffff;
}

.account-list {
  background-color: #fafafa;
  border-bottom: 1px solid #dfdfdf;
}

.btn-ornage:hover {
  background: #ff7b39;
  color: #ffffff;
}

.chart_area {
  border: 1px solid #dddddd;
  background: #ffffff;
  padding: 15px;
}

.cloudsize {
  font-size: 100px;
}

.upload-border {
  border: 2px dashed #ccc;
  margin: 15px;
  color: #9faab1;
}

.prime_line_btn {
  border: 1px solid #1fb9df;
  background: #ffffff;
  color: #1fb9df;
}

.prime_line_btn:hover {
  border: 1px solid #00889e;
  background: #ffffff;
  color: #00889e;
}

/*tyep2-list-view*/

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.btns-search {
  background: #d8d8d8;
  border: 1px solid #cccccc;
}

.btn-link {
  color: #424242;
}

.btn-link:hover {
  color: #000000;
  text-decoration: none;
}

.btn-link:focus {
  color: #000000;
  text-decoration: none;
}

.card-dtls-txt {
  color: #353535 !important;
}

/**Employee list CSS start**/
.hdr-bottom-border {
  border-bottom: 1px solid #bababa;
}

.content-section p {
  font-size: 14px;
  color: #686d71;
  margin-bottom: 5px;
}

.edit-btn {
  color: #fff;
  background-color: #f94e4e;
}

.list-hd {
  font-weight: normal;
  color: #686d70;
  font-size: 20px;
}

.list-hdd {
  font-weight: normal;
  color: #686d70;
  font-size: 2.5rem;
  text-align: center;
}

.form-focus-none {
  color: #666;
  font-size: 14px;
}

.form-focus-none:focus {
  box-shadow: none;
  border-color: none !important;
}

.table-bordr {
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal !important;
}

.name-text {
  text-decoration: none !important;
}

.bt-delete {
  color: #fff;
  background-color: #e23939;
  font-size: 10px;
  border-radius: 0;
}

.bt-delete:hover {
  background-color: #e22e2e;
}

/****login page CSS***/
.login-bg {
  background-image: url("../images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.form-login {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  margin: auto;
}

.form-login h1 {
  color: #686d71;
  font-weight: 700 !important;
  font-size: 20px;
}

.form-login .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  background: none;
}

.search-log-btn {
  color: #fff;
  background-color: #1ca1cd;
  width: 100%;
}

/**form section**/

.input-field {
  position: relative;
  width: 100%;
  height: 60px;
  line-height: 44px;
}

.input-field label {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  color: #47494a;
  transition: 0.2s all;
  cursor: text;
  font-size: 16px;
  text-align: start;
  font-weight: 400;
}

.input-field input {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #d3d3d3;
  box-shadow: none;
  color: #111;
}

.input-field input:invalid {
  outline: 0;
  color: #ff2300;
  border-color: #ff2300;
}

.input-field input:focus,
.input-field input:valid {
  border-color: #866e6d;
}

.input-field input:focus~label,
.input-field input:valid~label {
  font-size: 13px;
  top: -24px;
  color: #7a7c7d;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #0062cc !important;
  background-color: #0d57a6 !important;
}

.custom-control-label {
  cursor: pointer;
}

.login-input {
  box-shadow: none !important;
  border-radius: 0;
  background-color: transparent;
}

/**form section end**/

.logo {
  width: 180px;
}

.btn-login {
  background-color: #1fb9df;
  border: none;
  margin-top: 1.5rem;
}

.btn-login:hover {
  background-color: #16a9ce;
}

.btn-login:focus {
  background-color: #16a9ce !important;
  box-shadow: none !important;
}

.form-bg {
  background-color: #fff;
  padding: 50px 23px;
  box-shadow: 0px 2px 7px 0px #ccc;
  border-radius: 10px;
}

.form-bg-version2 {
  background-color: #fff;
  padding: 14px 23px 40px 23px;
  border-radius: 10px;
}

.or {
  color: #686d71;
}

.nav-a {
  color: #353535 !important;
}

.nav-pay .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000 !important;
  background-color: #feffff;
}

.pay-sub {
  color: #565656;
}

/*Pricing*/
.payment-g {
  background-color: #1fb9df;
  color: #fff;
}

.pricing_sec {
  color: #ffffff;
}

.success-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 5px 6px 5px -1px rgb(0 0 0 / 27%);
  -webkit-box-shadow: 5px 6px 5px -1px rgb(0 0 0 / 27%);
  -moz-box-shadow: 5px 6px 5px -1px rgba(0, 0, 0, 0.27);
  color: #000;
  padding: 15px;
  box-sizing: border-box;
}

.free-pricing {
  background-color: #62d67d;
  padding: 23px;
  color: #fff;
}

.basic-pricing {
  background-color: #fdb296;
  padding: 23px;
  color: #fff;
}

.anual-pricing {
  background-color: #1fb9df;
  padding: 23px;
  color: #fff;
}

.pricingtxt {
  color: #878787;
  font-size: 14px;
  line-height: 2;
}

.font-pricing {
  color: #666 !important;
}

.font-unpricing {
  color: #ccc !important;
}

.pr-wd {
  font-weight: 400;
}

.md-p {
  color: #727572;
  font-size: 14px;
  padding-left: 38px;
  margin-bottom: 0px;
  line-height: 37px;
}

.yen {
  color: #424242;
  font-size: 30px;
}

.yen-pay {
  color: #424242;
  font-size: 16px;
}

.no-list-icon {
  list-style: none;
}

.no-list-icon li {
  line-height: 35px;
}

/*Contact us*/

.contact-bg {
  background: url("../images/contact-us.jpg") no-repeat;
  background-size: cover;
  height: 400px;
  background-position: center center;
}

.contact_box {
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}

/*Card details*/
.equal {
  display: flex;
  flex-wrap: wrap;
}

.content-section h5 {
  color: #686d70;
}

.card-hd {
  background-color: #1fb9df;
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.card-hd h4 {
  color: #fff;
  font-size: 17px;
}

.border-radius {
  border-radius: 15px;
  border: #ededed;
}

.card-bd {
  background-color: #fff;
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  display: flex;
}

.cardbody {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.delete-btn {
  border: 1px solid #f94e4e;
  border-radius: 7px;
  background-color: #fff;
  color: #f94e4e;
}

.btn-yes {
  background-color: #f94e4e;
  color: #fff;
}

.canc-btn {
  background-color: #b7b5b5;
}

/**log data**/
.date-icon {
  position: absolute;
  right: 7px;
  top: 11px;
  font-size: 16px;
  color: #666;
}

.date-inp {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.filter-btn {
  color: #fff;
  background-color: #1ca1cd;
}

.export-btn {
  color: #000;
  background-color: #47dab3;
}

.log-arrow {
  font-size: 20px !important;
}

.log-bg {
  background-color: #caeef7;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.tilde_ch {
  position: relative;
  width: 0px;
  top: 38px;
  font-weight: bold;
}

.log-header {
  background-color: #1fb9df;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#side {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 100;
  width: 56px;
  transition: all 0.5s linear;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  padding: 0px;
}

.side_expnd {
  width: 250px;
}

/* Style the sidenav links and the dropdown button */
#side a,
.dropdown-btn {
  text-decoration: none;
  font-size: 14px;
  color: #262e80 !important;
  display: block;
  border: none;
  background: none;
  width: 240px;
  text-align: left;
  cursor: pointer;
  outline: none;
  margin: 5px;
  margin-bottom: 16px;
}

.selection {
  background: #e7e9f3 !important;
  border-radius: 5px;
  margin-right: 5px;
}

.icon_selection {
  background: #e7e9f3 !important;
  border-radius: 5px;
}

.imsize {
  width: 35px;
  padding: 5px;
}

/* On mouse-over */
#side a:hover,
.dropdown-btn:hover {
  color: #f1f1f1;
}

.dropdown-container {
  display: none;
  padding-left: 3rem;
}

#side .dropdown-btn-side {
  padding: 8px 14px !important;
  text-decoration: none;
  font-size: 14px;
  color: #262e80;
  display: block;
  border: none;
  background: none;
  width: 200px;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
  margin-top: 12px;
}

.icn {
  display: inline;
  padding-right: 18px;
  font-size: 20px;
}

.icn1 {
  display: inline;
  padding-right: 22px;
  font-size: 20px;
}

.icwidth {
  width: 30px;
  text-align: center;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10001;
  cursor: pointer;
}

.floating_sec {
  padding-left: 56px;
}

.floating_cont {
  margin-top: -200px;
  padding-left: 85px;
  position: relative;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

/*--------------------------------------------------*/

.modal_menu {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
}

/* Modal Content */
.modal_menu-content {
  background-color: #ffffff;
  margin: auto;
  padding: 15px;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  width: 230px;
  position: absolute;
  left: 250px;
  bottom: 20px;
  box-shadow: 8px 7px 5px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 8px 7px 5px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 8px 7px 5px 0px rgba(0, 0, 0, 0.18);
}

.modal_menu-content ul {
  padding-left: 0px;
}

.modal_menu-content ul li {
  border-bottom: 1px solid #f4f4f4;
}

.modal_menu-content ul li:hover {
  border-bottom: 1px solid #f4f4f4;
  background: #f4f4f4;
}

.modal_menu-content ul li a {
  color: #000000;
  text-decoration: none;
}

.modal_menu-content ul li a:hover {
  color: #000000;
  text-decoration: none;
}

/*Workshop details*/
.nav-tabs .nav-link {
  background: #e7e9f3;
  margin: 0 5px;
}

.mtp5 {
  margin-top: -5px;
}

.btn-loc {
  position: relative;
  top: 28px;
  z-index: 10;
}

.ft-size-20 {
  font-size: 20px;
}

.line-button {
  border: 1px solid #191d6a !important;
  background: #ffffff !important;
  color: #191d6a !important;
}

.line-button:hover {
  border: 1px solid #191d6a !important;
  background: #e7e9f3 !important;
  color: #191d6a !important;
}

.line-button-red {
  border: 1px solid #c23d61 !important;
  background: #ffffff !important;
  color: #c23d61 !important;
}

.line-button-red:hover {
  border: 1px solid #c23d61 !important;
  background: #c23d61 !important;
  color: #ffffff !important;
}

.bordr-left {
  border-left: 1px solid #cdcdd6;
}

.link-text {
  color: #191d6a;
  text-decoration: underline;
  font-weight: 600;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  margin: 20px 0px;
  height: 279px;
  display: flex;
  border: 1px solid #cdcdd6;
  padding: 15px;
  word-break: break-all;
  overflow: auto;
  width: 100%;
}

.white_bg_emailTemp {
  background: #ffffff;
  border-radius: 5px;
  height: 279px;
  display: flex;
  border: 1px solid #cdcdd6;
  word-break: break-all;
  /* overflow: scroll;*/
  width: 100%;
}

.btm-alert {
  background: #fdf5e2;
  color: #896a15;
  border: none;
  display: flex;
}

.green {
  color: #2b7347 !important;
}

.er_txt {
  color: #c23d61;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0px;
  line-height: 1.25;
  color: #181924;
  background-color: inherit !important;
  border: none;
  border-radius: 50%;
  margin-top: 15px;
}

.select-page {
  border: 1px solid #c3c7e2;
  border-radius: 50%;
  color: #191d6a;
}

.txt-border {
  border-left: 4px solid #6e6f7c;
  padding: 3px;
}

.bl-alert {
  background: #e4f3fc !important;
  border: none !important;
  color: #1c6a95;
}

.yl-alert {
  background: #fdf5e2 !important;
  border: none !important;
  color: #896a15 !important;
}

.fade-blue {
  background: #8c8eb5 !important;
}

.td-min-height {
  height: 500px;
  text-align: center !important;
  vertical-align: middle !important;
}

@media only screen and (max-width: 768px) {
  .btn-loc {
    position: relative;
    top: 0px;
    margin-bottom: 10px;
  }

  .bordr-left {
    border-left: none;
  }
}

@media only screen and (max-width: 767px) {

  /* .floating_sec {
    left: 0px;
    background: #fff;
  }*/
  .floating_sec {
    left: 0px;
    background: transparent;
  }

  .search-log-btn {
    color: #fff;
    background-color: #1ca1cd;
    width: 75px;
  }

  .floating_cont {
    margin-top: -200px;
    padding-left: 15px;
    position: relative;
  }

  .hide-dot {
    display: none;
  }

  .small-log {
    display: none;
  }

  .tilde_ch {
    display: block;
    position: relative;
    width: 100%;
    top: 13px;
    text-align: center;
    font-weight: bold;
  }

  .side-img-hide {
    display: none;
  }

  .cor_center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  #side {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 100;
    width: 56px;
    transition: all 0.5s linear;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    padding: 0px;
    display: block;
  }

  .container-padding {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 768px) {
  .overflow-log {
    display: none;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .rightSide {
    width: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .col-margin {
    padding: 56px 14px 95px 14px !important;
  }

  .mtp5 {
    margin-top: -87px !important;
    margin-bottom: 11px;
  }
}

/*custom*/
.sideBarBody:hover {
  width: 255px !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #000000 !important;
  background-color: inherit !important;
  border: 1px solid gray;
}

.page-link {
  margin-left: 12px;
  margin-top: 15px;
}

.isRequired {
  border: 1px solid red !important;
}

.disableButton {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.head-bottom {
  background: #f8f8fa;
  border-bottom: 1px solid #dee2e6;
  height: 40px;
}

.form-check-input1 {
  margin-top: 0.5rem;
  margin-right: 0.25rem;
}

.form-check-input:checked {
  background-color: #191d6a !important;
  border-color: #191d6a !important;
}

.hold-Type {
  display: flex;
  gap: 20px;
}

.inv-answer {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.hold_info {
  max-height: 271px !important;
  min-height: 271px !important;
}

.modalCommon {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  min-width: 400px !important;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.7) !important;
  box-shadow: 24px !important;
  border-radius: 0.3rem !important;
  background-color: #ffffff !important;
  max-height: 90vh;
  overflow-y: auto;
}

.authModal {
  width: 800px !important;
}

.participantdetailsModal {
  overflow-y: auto !important;
  width: 640px !important;
}

.deleteModal {
  width: 480px !important;
  overflow-y: visible !important;
}

.regModal {
  width: 700px !important;
}

.errorModal {
  width: 85% !important;
  overflow: auto;
  max-height: 90vh;
}

.remarks-ellipse {
  max-width: 200px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th,
td {
  /* min-width: 0px ; */
  max-width: 260px;
}

.deleteModal {
  width: 480px !important;
}

.regModal {
  width: 700px !important;
}

.yellow-background {
  background-color: #fdf5e2;
  /* padding: 18px;
  margin-left: -17px;*/
  padding-top: 14px;
  padding-bottom: 14px;
}

.single-reg-modal {
  width: 480px !important;
}

.name-elips {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pre-style {
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 1rem;
}

input[type="checkbox"] {
  accent-color: #5863ae;
}

input[type="checkbox"]:checked {
  background-color: #5863ae;
  /* Change this to the desired color */
}

.search-ft {
  padding-right: 40px !important;
}

.no-participantReg {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.textBold {
  font-weight: 1000;
}

.alert-tenplate-align {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boxShadow {
  box-shadow: 0 2px 4px rgba(0, 0, 255, 0.3);
}

.modal-table-responsive {
  max-height: 61.5vh;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.min-width-confirm-email {
  min-width: 139px;
}

.deaprtment-style {
  display: flex;
  gap: 20px;
}

.department-label {
  min-width: 40px;
}


.error-fallback {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  background-color: #f8fcfd;
}

.offline-wrap {
  background-color: #f8fcfd;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.headingone {
  font-family: Gilroy-Heavy;
  font-size: 34px;
  color: black
}

.subhead {
  color: black
}

.nodata-table {
  min-height: 65px;
  text-align: center;
  align-items: center;
}

/*Version 2, Heading*/
header {
  background: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
}

.header_height {
  height: 100px;
}

.top_links {
  color: #191D6A;
  text-decoration: none;
}

.main_bg {
  background: #f8f8fa;
}

.m_sec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-card {
  flex: 0 0 32.333333%;
  border-radius: 4px;
  border: 1px solid #CDCDD6;
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);

}

.card_top {
  height: 100%;
  width: 100%;
  color: #ffffff;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(to bottom,
      #3C49A1 0%,
      #3C49A1 50%,
      #ffffff 50%,
      #ffffff 100%);
}

.card_top_evaluation {
  height: 100%;
  width: 100%;
  color: #ffffff;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(to bottom,
      #2f7196 0%,
      #2f7196 50%,
      #ffffff 50%,
      #ffffff 100%);
}

.card_top_followup {
  height: 100%;
  width: 100%;
  color: #ffffff;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(to bottom,
      #7f3e96 0%,
      #7f3e96 50%,
      #ffffff 50%,
      #ffffff 100%);
}

.card-subtext {
  color: #656676;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 40px;
  height: 40px;


}

.sep_line {
  border-top: 1px solid #eeeef2;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.multi-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.multi_head {
  font-size: 19px;
  font-weight: 600;
}

.reponsivemenu {
  display: block;
}

/*Responsive-menu*/
.navbars {
  padding: 15px;
  position: absolute;
  right: 10px;
  width: 100%;
  top: 10px;
}

.menu-icon {
  color: #000;
  font-size: 24px;
  cursor: pointer;
  text-align: right
}

.menu {
  display: none;
  flex-direction: column;
  background-color: #ffffff;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  text-align: left;
}

.menu a {
  padding: 15px;
  text-decoration: none;
  color: #181924;
  display: block;
}

.menu a:hover {
  background-color: #E7E9F3;
}

.reponsiverev {
  display: none;

}

.shade {
  color: #181924;
}

.content_text {
  color: #4F5062
}

.red_line_btn {
  border-radius: 20px;
  border: 2px solid #C23D61;
  padding: 4px 12px;
  color: #C23D61;
  font-size: 12px;
  display: block;
  cursor: pointer;
}

.invent_img {
  max-width: 75px;
  max-height: 75px;
}

.ft20 {
  font-size: 20px;
}

.botom_color {
  color: #484952;
}

.foot_sep {
  border-top: 1px solid #CDCDD6;
}

.form-inputs .form-control:focus {
  box-shadow: none;
  border: 1px solid #000;
}

.form-inputs i {
  position: absolute;
  right: 10px;
  top: 15px;
}

.serach-icon {
  position: relative;
  top: -33px;
  margin-bottom: -35px;
  right: 10px;
  width: 35px;
  float: right;
  background: #fff;
  height: 30px;
  z-index: 5;
}


/******************************************NEW CSS******************************************************************************/

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0
  }

  to {
    right: 0;
    opacity: 1
  }
}

.bx_brder {
  border: 1px solid #E7E9F3
}


/*Inventory answer*/
.inv_bg {
  background: #364197;
}

.inv_head {
  font-family: 'Arsenal', sans-serif;
  font-size: 40px;
  color: #b1b7d8;
}

.inv_subhead {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
}

.inv_small {
  color: #ffffff;
  font-size: 16px;
}

.inv_sep {
  border-right: 1px solid #262E80;
  margin-top: 15px;
  margin-bottom: 15px
}

.inv_data {
  font-size: 14px;
  color: #C3C7E2;
}

.inv_time {
  font-size: 16px;
  color: #ffffff;
}

.inv_highlight {
  background: #E7E9F3 !important;
}

.inv_highlight_head {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.w40 {
  max-width: 900px;
}

.inv-border-left {
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #CDCDD6;
  border-right: none;
  background: #E7E9F3;
  width: 14.28%;
  color: #181924;
}

.inv-border-box {
  border: 1px solid #CDCDD6;
  border-right: none;
  width: 14.28%;
  color: #181924;
  background: #ffffff;
}

.inv-border-right {
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #CDCDD6;
  width: 14.28%;
  color: #181924;
  background: #ffffff;
}

.fnt18 {
  font-size: 18px;
}

.inv_text_ht {
  min-height: 45px;
}

.inv_rev {
  display: flex;
  flex-direction: column;
}

.inv_text_limit {
  color: #4F5062;
}

.inv_full_btn {
  background: #191D6A;
}

.blue_line_button {
  border: 1px solid #191D6A;
  background: #ffffff;
  color: #191D6A;
}

.blue_line_button:hover {
  border: 1px solid #191D6A;
  background: #e4e8f9;
  color: #191D6A;
}

.grey_line_button {
  border: 1px solid #cdcdd6;
  background: #ffffff;
  color: #181924;
}

.grey_line_button:hover {
  border: 1px solid #cdcdd6;
  background: #e7e9f3;
  color: #181924;
}

.grey_line_button_active {
  border: 1px solid #cdcdd6;
  background: #e7e9f3;
  color: #181924;
}

.card_bg_new {
  background: #E4E4EA;
}

.fnt12 {
  font-size: 12px;
}

.drkback {
  color: #3e3f48;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #cdcdd6;
  border-radius: 0rem;
  margin-top: 2px;
  border-radius: 8px;
}

.grad {
  background-image: linear-gradient(180deg, #9b9b9b, #cdcdd6);
}

.progress-bar {
  background: #2e388c;
}

.td_higlight {
  background: #fdf5e2;
  font-weight: bold;
  color: #000;
}

.w42 {
  width: 42px;
}

.fnt20 {
  font-size: 20px;

}

.gapbottom {
  padding-bottom: 15px;
}

.res_logo {
  width: auto
}

.btom_txt_colr {
  color: #6e6f7c
}


.card2color {
  background: #2F7196;
}

.form-check-input:checked {
  background-color: #191d6a;
  border-color: #191d6a;
}

.pink_card {
  background: #7f3e96
}

.inv_highlight_white {
  background: #FFFFFF !important;
}


@media only screen and (max-width: 992px) {
  .w40 {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .inv_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .border-ch {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #CDCDD6 !important;
  }


}

@media only screen and (max-width: 767px) {
  .inv_text_ht {
    padding-left: 10px;
    min-height: auto;
    padding-top: 0px !important;
  }

  .btn-loc {
    position: relative;
    top: 0px;
    margin-bottom: 10px;
  }

  .resp_de {
    display: block !important;

  }

  .bordr-left {
    border-left: none;
  }

  .reponsivemenu {
    display: none;
  }

  .reponsiverev {
    display: block;

  }

  .inv_sep {
    border-right: none;
    border-bottom: 1px solid #262E80;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 10px;

  }

  .res_logo {
    width: 90px;
  }

  .fl-dir {
    flex-direction: column;
  }

  .flx_fullwidth {
    width: 100%;
  }

  .invrevflt {
    float: left;
  }

  .inv_brd_right {
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #CDCDD6;
  }

  .inv_rev {
    display: flex;
    flex-direction: row-reverse;
    align-self: flex-start;
    align-items: center;
    padding: 10px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {

  .main-card {
    flex: 0 0 48.333333%;
    border-radius: 4px;
    border: 1px solid #CDCDD6;
    background: #FFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);

  }

}

@media only screen and (min-width: 1200px) {

  .main-card-min-width {
    min-width: 20rem;
  }
}

@media only screen and (min-width: 990px) and (max-width: 1200px) {

  .main-card-min-width {
    margin-right: 20px;
    min-width: 25rem;
  }
}

@media only screen and (max-width: 767px) {

  /* .floating_sec {
    left: 0px;
    background: #fff;
  }*/
  .floating_sec {
    left: 0px;
    background: transparent;
  }

  .search-log-btn {
    color: #fff;
    background-color: #1ca1cd;
    width: 75px;
  }

  .floating_cont {
    margin-top: -200px;
    padding-left: 15px;
    position: relative
  }

  .hide-dot {
    display: none;
  }

  .small-log {
    display: none;
  }

  .tilde_ch {
    display: block;
    position: relative;
    width: 100%;
    top: 13px;
    text-align: center;
    font-weight: bold
  }

  .side-img-hide {
    display: none;
  }

  .cor_center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  #side {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 100;
    width: 56px;
    transition: all .5s linear;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    padding: 0px;
    display: block;
  }

  .container-padding {
    padding-left: 1rem;
  }

  .main-card {
    flex: 0 0 98%;
    border-radius: 4px;
    border: 1px solid #CDCDD6;
    background: #FFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
    margin: 1%;
  }

}

@media only screen and (max-width: 1920px) and (min-width: 768px) {

  .overflow-log {
    display: none;
  }

}

@media only screen and (max-width: 480px) and (min-width: 320px) {

  .rightSide {
    width: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .col-margin {
    padding: 56px 14px 95px 14px !important;

  }

  .mtp5 {
    margin-top: -87px !important;
    margin-bottom: 11px;
  }

  .radarChart_mobile {
    min-width: 350px;
    margin-left: -42px;
  }

  .question-head-text {
    font-size: 11px !important;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

@media (max-width: 577px) {
  .position-inherit {
    padding-left: 10px !important;
    padding-right: 10px !important;
    position: inherit;
  }
}

.me-auto {
  margin-right: auto !important;
}

.custom-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: static !important;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.position-inherit {
  padding-left: 0px;
  padding-right: 0px;
  position: inherit;
}

.padding-sides-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.min-height-44 {
  min-height: calc(92vh - 90px);
}

.padding-sides-15px {
  padding-left: 15px;
  padding-right: 15px;
}

.react-time-picker__wrapper {
  border: none !important;
}

.back-btn {
  color: var(--corporate-900, #191D6A);
  /* //font-family: Hiragino Kaku Gothic Pro; */
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: normal;
  cursor: pointer;
}

.bg-alert {
  background: #F5F4F4 !important;
  border: none !important;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
}

.inventoryModal {
  width: 707px;
  overflow-y: unset !important;
}

.date-label {
  color: var(--Shade-600, #6E6F7C);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 10px;
}

.date-data {
  color: var(--shade-900, #181924);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
  /* 100% */
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.fw-bold {
  font-weight: 700 !important;
  padding-left: 10px;
}

.max-width-900 {
  max-width: 900px;
}

.options {
  max-width: 100%;
  width: 100%;
}

.position-inherit-radio {
  position: inherit;
}

.text-end {
  text-align: right !important;
}

.evaluationModal {
  width: 350px;
  overflow-y: unset !important;
}

.main-settings {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  padding-bottom: 20px;
}

.sub-headsetting {
  color: var(--Shade-900, #181924);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sub-headingsetting {
  color: var(--Shade-900, #181924);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.setting-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.section-item {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid var(--Shade-100, #E4E4EA);
}

.section-left {
  color: var(--Shade-300, #CDCDD6);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.right-settings {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 8px;
}

.sucess-setting {
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Success-400, #66AF88);

  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.error-setting {
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Error-700, #C23D61);

  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.imgsection {
  width: 10px;
  height: 16px;
}

@media only screen and (max-width: 768px) {
  .res_logo {
    width: 90px;
  }

  .question-head-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 450px) {
  .mobile-only {
    display: inline;
  }

  .mobile-hide {
    display: none;
  }

  .option_btn_width {
    max-width: 100%;
    white-space: initial;
    text-align: left;
  }

  .privacy_link {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 451px) {
  .mobile-only {
    display: none;
  }
}

.tablename-wrap {
  display: block;
  overflow: hidden;
  width: 60%;
}

.pagination>.page-item.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.remainderemailModal {
  width: 28% !important;
}

.menu_text {
  font-size: 12px;
  float: right;
  margin-left: 5px;
  margin-right: -12px;
}

.w50 {
  width: 50%;
}

.marginTop_-8px {
  margin-top: -8px;
}

.marginRight_-25px {
  margin-right: -25px;
}

.marginBottom_-5px {
  margin-bottom: -5px !important;
}

.marginTop_5px {
  margin-top: 5px;
}

.z-index-1000 {
  z-index: 1000;
}


.form-check-label {
  display: inline-block;
  /* Ensure the label is treated as a block element */
  max-width: 80%;
  /* Set a maximum width for the label */
  line-height: 1.5;
  /* Adjust line height as needed */
}

/* Optional: Adjust the checkbox size if needed */
.form-check-input {
  transform: scale(1);
  /* Adjust the scale factor as needed */
  margin-right: 15px;
}

.top-btngrp .btn-primary {
  padding: 6px 16px;
  gap: 8px;
  width: auto;
  height: 37px;
  background: #191D6A;
  border-radius: 5px;
  color: #fff;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  border: none;
}

.top-btngrp .btn-primary:hover {
  color: #fff;
  background-color: #191D6A;
  border-color: #191D6A;
}

.top-btngrp .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #191D6A;
  border-color: #191D6A;
  box-shadow: none !important
}

.top-btngrp .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none
}

.top-btngrp .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2E388C;
  border-color: #2E388C;
}

.top-btngrp .dropdown-item:not(:disabled):not(.disabled):active {
  color: #fff;
  text-decoration: none;
  background-color: #2E388C;
}

.top-btngrp .dropdown-toggle::after {
  display: none !important;
}

.top-btngrp .dropdown-item:not(:disabled):not(.disabled):hover {
  color: #FFF;
  text-decoration: none;
  background-color: #2E388C;
}

.tooltip-inner {
  background-color: #4a4a4a !important;
}

.footer-card {
  flex: 0 0 calc(33.33% - 20px);
  overflow: hidden;
}

.margin-right-footer {
  margin-right: 30px;
}

.remainserlistModal {
  overflow: auto;
  max-height: 35vh;
}

.inv_quesize {
  font-size: 11px;
}

.questionheader_text {
  font-size: 20px;
  margin-bottom: 5px;
}

/* external links - participant dashboard  */
.dashboard-link-container {
  display: flex;
  min-height: 5rem;
  width: 100%;
}

.item-link-dashboard {
  text-align: left;
  font-size: 14px;
  padding-left: 0;
  width: auto;
  margin-right: 20px;
  font-weight: 300;
}

.dashboard-link-img {
  width: 24px;
  margin-right: 12px;
}

.dashboard-link-btn {
  width: 12px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.item-link-dashboard a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .dashboard-link-container {
    flex-direction: column;
    align-items: left;
  }

  .item-link-dashboard {
    width: 100% !important;
    margin-bottom: 20px;

    .dashboard-link-img {
      width: 24px;
      margin-right: 10px;
    }

    .dashboard-link-btn {
      width: 14px;
      margin-left: 12px;
    }
  }
}

/* modal showing external links - admin screen */
.externalLinksdetailsModal {
  overflow-y: auto !important;
  width: 500px !important;
}

.admin-links-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.admin-links-item:hover {
  background-color: #f0f0f0;
}

.admin-links-item a {
  text-decoration: none;
  color: inherit;
}

.admin-links-item-img {
  width: 40px;
  margin-right: 10px;
}

.admin-links-item-btn {
  width: 12px;
  margin-left: 10px;
}


.sidebar-hr {
  border: 1px solid #E4E4EA;
  margin: 0px;
}

@media only screen and (max-width: 768px) {
  .type2_responsive_border {
    border: 1px solid #CDCDD6 !important;
  }

  .responsive-flex {
    max-width: 100% !important;
  }

  .responsive-choice-input {
    display: initial !important;
    text-align: left !important;
  }

  .responsive-choice-label2 {
    display: block !important;
  }

  .responsive-choice-label1 {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .responsive-choice-label1 {
    display: block !important;
  }

  .responsive-choice-label2 {
    display: none !important;
  }
}

.ev-progressbar-custom-margin {
  margin-bottom: 0 !important;
}

.ev-progressbar-label-custom-margin {
  margin-bottom: 0 !important;
  margin-top: 3rem;
}

.ev-qn-description {
  margin-top: 3rem;
  margin-bottom: -1.5rem;
  font-size: 16px;
  margin-left: 3px;

}

.fw-semibold{
  font-weight:600
}


/* ----------------------------------SP Admin CSS---------------------------------------- */

.salesperson-sidebar {
  height: 96%;
  position: absolute;
  left: 0;
  background-color: #ffffff;
  z-index: 100;
  width: 238px;
  overflow: hidden;
  background: #ffffff;
  padding-top: 16px;
  display: block;
  margin-top: 2px;
  padding: 16px 14px;
  border-right: 1px solid #E4E4EA;
  border-bottom: none;
}

.salesperson-container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.salesperson-search-label {
  /* padding-top: 11px; */
  display: flex;
  font-weight: bold;
  font-size: 16px;
}

.salesperson-content {
  margin-left: 235px;
  margin-right: 12px;
  padding-right: 10px !important;
}

.salesperson-common-btn {
  padding: 6px 16px;
  gap: 8px;
  width: auto;
  height: 37px;
  border-radius: 5px;
  color: #fff;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  border: none;
}

.blue-btn {
  background: #191d6a;
  color: #ffffff;
}

.white-btn {
  background: #ffffff;
  color: #c23d61;
  border-color: #c23d61;
  border: 1px solid red;
}

.white-btn:hover {
  background: #c23d61;
  color: #ffffff;
}

.white-btn:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.salesperson-common-button:hover {
  background-color: #2e388c;
}

.salesperson-btn-group {
  display: flex;
  gap: 10px;
  align-items: right;
  /* min-width:78%; */
  /* justify-content: flex-end; */
  margin-left: "auto"
}

.nav-border {
  border-bottom: none !important
}

.salesperson-sidebar.selected {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.salesperson-sidebar-link {
  text-decoration: none;
  font-size: 14px;
  color: #262e80 !important;
  display: block;
  border: none;
  background: none;
  width: 185px;
  text-align: left;
  cursor: pointer;
  outline: none;
  margin: 5px;
  margin-bottom: 16px;
  padding: 10px 19px 10px 19px;
  font-weight: bold;
}

.salesperson-sidebar-link.selected {
  background: #e7e9f3 !important;
  border-radius: 5px;
  margin-right: 5px;
}

.header_mg {
  margin-left: 0px !important;
}

.sp-table-child th:first-child,
.sp-table-child td:first-child {
  max-width: 100px;
  width: 100px;
  overflow: hidden;
  /* border: #000; */
}

.salesperson-error {
  margin-top: 1px;
  margin-left: 200px;
  padding-right: 0px !important;
}

.salesperson-search-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  margin-left: 0px;
}

.salesperson-search-container {
  display: flex;
  align-items: center;
  gap: 10px
}

.admincreateModal {
  width: 707px;
}
