.table-inv-response th,
.table-inv-response td {
  min-width: unset !important;
  max-width: unset !important;
}

.participant-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 105%;
  margin-left: -10px;
  margin-right: -10px;
}

.participant-card {
  flex: 0 0 calc(33.33% - 20px);
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #CDCDD6;
}

.participant-card img {
  width: 100%;
  height: auto;
}

@media (max-width: 993px) {
  .participant-card {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 767px) {
  .participant-card {
    flex: 0 0 calc(100% - 20px);
  }
}

@media print {
  header, footer, .inv_bg, .print-download-bar, .previous-page-link{
    display: none;
  }
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #cdcdd6;
    border-radius: 0rem;
    margin-top: 2px;
    border-radius: 8px;
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width .6s ease;
  }

  .progress-bar {
    background: #2e388c;
  }
  .td_higlight {
    background: linear-gradient(#fdf5e2 , #fdf5e2);
    font-weight: bold;
    color: #000;
  }
}

.menu {
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px -2px;
}

.menu a {
  padding-left: 35px;
}
.no-hover:hover {
  background-color: white;
  color: initial;
}
.no-outline:focus {
  outline: none;
}
.red-border {
  border: 1px solid red;
}
.red-border-hover:hover{
  border: 1px solid red;
}
.width-100{
  width: 100%;
}
.sep_line:hover{
  background-color: #E7E9F3;
}
.icon-background{
  cursor: pointer;
  padding-top: 7px;
  width: 90px;
  height: 70px;
  border-radius: 8%;
  transition: background-color 0.3s ease;
}
.icon-background:hover{
  background-color: #E7E9F3;
}
.arrow-image {
  transition: transform 0.3s ease-in-out; 
}
.ps-2.ft20.botom_color.invoice-p:hover .arrow-image {
  transform: translateX(6px); 
}
.transformed {
  transform: translateX(6px);
}

.home-header{
  font-weight: 600;
  font-size: 24px;
  color: #181924;
}

.cardText-attend{
  font-weight: 600;
  font-size: 16px;
  color: #181924;
}
.red-line-btn-margin{
  margin-bottom: 3px;
}
.inv_details textarea,
.evl_details textarea,
.flp_details textarea {
  resize: none; 
}

#notfound {
  position: relative;
  height: 30vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.notfound p {
  margin-top: 10px;
}

.radar-chart-view{
  height:350px;
  margin-top: -55px;
}
.min-height-30{
  min-height: 30px;
}

.apexcharts-text{
  transform: translate(0px, 7px);
  }
  
  .apexcharts-xaxis text:first-child {
    transform: translateY(-12px); 
    text-anchor: middle;
  }
  
  .apexcharts-xaxis text:nth-child(2) {
    transform: translateX(18px) !important;
    text-anchor: middle;
  }

  .apexcharts-xaxis text:nth-child(3) {
    /* transform: translateX(69px) !important;
    transform: translateY(8px); */
    transform: translate(12px, 8px) !important;
    text-anchor: middle;
  }
  
  .apexcharts-xaxis text:nth-child(4) {
    /* transform: translateX(-48px) !important;
    transform: translateY(8px) ; */
    transform: translate(-48px, 8px) !important;
    text-anchor: middle;
  }
  
  .apexcharts-xaxis text:nth-child(5) {
    transform: translateX(-65px) !important;
    text-anchor: middle;
  }