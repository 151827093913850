@charset "utf-8";
/* CSS Document */

body {
  font-size: 14px;
  font-weight: normal;
  background: #ffffff;
  color: #000000;
  min-height: 100vh;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック",
    "MS Gothic", "Noto Sans CJK JP", TakaoPGothic, sans-serif;
}

@font-face {
  font-family: "Hiragino Kaku Gothic Pro";
  src: url(../Fonts/Hiragino\ Kaku\ Gothic\ Pro\ W3.otf);
}

@font-face {
  font-family: "HiraginoPron W3";
  src: url(../Fonts/Hiragino\ Kaku\ Gothic\ ProN\ W3.otf);
}

@font-face {
  font-family: "HiraginoPron W6";
  src: url(../Fonts/Hiragino\ Kaku\ Gothic\ Pro\ W6.otf);
}

@font-face {
  font-family: "SF pro text";
  src: url(../Fonts/SF-Pro-Text-Regular.otf);
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
  outline: 0px auto -webkit-focus-ring-color !important;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
}

button:focus {
  outline: none !important;
}

* {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.flex-fill {
  flex: 1 1 auto;
}

.rounded-lg {
  border-radius: 1rem;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.form-group {
  margin-bottom: 0px;
}

/****login start****/

.forgotpw {
  color: #1fb9df;
}

.forgotpw:hover {
  color: #059bc0;
  text-decoration: none;
}

.login_center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.three-login-bg {
  background-color: #f0f5fa;
}

.three-form-login {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.three-form-bg {
  background-color: #fff;
  padding: 88px 140px;
  border-radius: 5px;
  border-top: 8px solid #003c73;
  box-shadow: 0px 2px 6px rgba(0, 60, 115, 0.15);
}

.three-form-login h1 {
  color: #181924;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: "HiraginoPron W6";
}

.padding-top {
  padding-top: 48px;
}

.form-group {
  text-align: start !important;
}

.three-form-login .form-label {
  text-align: start !important;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #181924;
  margin-bottom: 1px;
}

.three-form-login .form-control {
  padding: 8px 16px;
  gap: 8px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cdcdd6;
  border-radius: 5px;
  color: #6e6f7c;
  font-size: 16px;
}

.mg-top {
  margin-top: 24px;
}

.link-button {
  padding: 8px 16px;
  gap: 8px;
  height: 37px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #191d6a;
  font-size: 14px;
  font-family: "Hiragino Kaku Gothic Pro";
}

.link-button:hover {
  background-color: #e7e9f3;
}

.common-button {
  padding: 6px 16px;
  gap: 8px;
  width: auto;
  height: 37px;
  background: #191d6a;
  border-radius: 5px;
  color: #fff;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.common-button:hover {
  background-color: #2e388c;
}

/****login end****/

/****reset password***/

.reset-p {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #181924;
  padding-top: 20px;
}

.success-alert {
  background: #eff8f3;
  border-radius: 5px;
  padding: 10px 16px;
  gap: 10px;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #2b7347;
  text-align: start;
  margin-top: 12px;
}

.form-sub-text {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #4f5062;
  margin-top: 4px;
}

.error-alert {
  background: #fbefef;
  border-radius: 5px;
  padding: 10px 16px;
  gap: 10px;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #c23d61;
  text-align: start;
  margin-top: 12px;
}

.icon-alert {
  margin-top: -5px;
}

/***reset end***/

/**** application form start *****/

.form-margin {
  margin-top: 38px;
  margin-bottom: 38px;
}

.three-form-application {
  width: 100%;
  max-width: 861px;
  margin: auto;
}

.three-form-application h1 {
  color: #181924;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: "HiraginoPron W6";
}

.form-label {
  text-align: start !important;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 600 ;
  font-size: 14px;
  line-height: 21px;
  color: #181924;
  margin-bottom: 1px;

}

.three-form-application.form-control {
  padding: 8px 16px;
  gap: 8px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cdcdd6;
  border-radius: 5px;
  color: #6e6f7c;
  font-size: 16px;
}

.border-label {
  border-right: 4px solid #ccd8e3;
  height: auto;
  text-align: right;
}

.right-col9 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.lb-fm {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  text-align: right;
  color: #003c73;
}

.gap-form {
  margin-top: 32px;
}

.font-16 {
  font-size: 16px;
}

.pd-font {
  font-family: "HiraginoPron W6";
  color: #181924;
}

.border-form {
  border: 1px solid #cdcdd6;
  padding: 32px;
}

.padding-btm {
  padding-bottom: 48px;
}

.pd-24 {
  padding-top: 24px;
}

/*****application form end**/

/*****table start****/
.heading1 {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #866941;
  margin-bottom: 4px;
}

.heading2 {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #181924;
}
.detail-heading {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #181924;
  width: auto;
}
.deatils-heading-style {
  font-family: "HiraginoPron W6";

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.col-margin {
  padding: 0px 14px 56px 14px;
}

/***table end***/

.header-bg {
  background: #f8f8fa;
  border-bottom: 1px solid #e7e9f3;
  padding: 0px 28px;
  margin-left: 55px;
}
.header-enterprise {
  background: #f8f8fa;
  border-bottom: 1px solid #e7e9f3;
  padding: 0px 28px;
}
.container-padding {
  padding-left: 5.5rem;
  padding-right: 28px;
}

.question {
  margin-top: 28px;
}

.pt-21 {
  padding: 21px 0px;
}

.col-search {
  width: 100%;
  max-width: 300px;
}
.btn-search {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 24px;
  top: 8px;
}

.search-ft {
  padding: 8px 16px;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cdcdd6;
  border-radius: 5px;
}

.search-ft:focus-visible {
  outline: none;
}

.table {
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0px !important;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #181924;
}

.table-border {
  border: 1px solid #e4e4ea;
  border-radius: 5px;
}

.thead-backg {
  background: #f5f4f4;
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4f5062;
  border-bottom: 1px solid #94959f;
}

.table-text {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;
  color: #262e80;
  cursor: pointer;
}

.table thead th {
  border-bottom: 1px solid #94959f;
  border-top: transparent !important;
  vertical-align: top !important;
}

.page-a {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px !important;
  margin-left: 12px;
  border: none;
  text-align: center;
  padding-top: 11px;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #181924;
}

.page-a:hover {
  background: #e7e9f3;
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #181924;
}

.page-item.active .page-a {
  background: #ffffff !important;
  border: 1px solid #c3c7e2 !important;
  border-radius: 20px;
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #191d6a;
}

.page-a:focus {
  outline: none;
  box-shadow: none;
}
.left-arrow {
  padding-left: 8px;
}
.right-arrow {
  padding-left: 14px;
}
.pop-head {
  /* height: 41px; */
  background: #f5f4f4;
  position: relative;
}
.close {
  position: absolute;
  right: 9px;
  top: 5px;
  font-size: 28px;
}
.pop-title {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #181924;
  padding: 10px 16px;
  padding-right: 30px;
}
.pop-pg {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #181924;
}
.pd-pop {
  padding: 32px;
}
.pop-button {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #191d6a;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #191d6a;
  border-radius: 5px;
}
.pop-button:hover {
  background: #e7e9f3;
  border: 1px solid #191d6a;
}
.exel-bg {
  background: #f8f8fa;
  border: 1px solid #9ba3cf;
  border-radius: 5px;
  margin: 20px 0px;
  height: 279px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.centrel-txt {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #191d6a;
}
.super-sub {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #4f5062;
}
.heading3 {
  font-family: "Hiragino Kaku Gothic Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #181924;
}
.border-right {
  border-right: 1px solid #cdcdd6;
}
.col-margin2 {
  padding-bottom: 39px;
}
.button-header {
  margin-top: -16px;
  margin-bottom: 16px;
}
.col-right {
  text-align: right;
}
.delete-btn {
  width: 60px;
  height: 37px;
  background: #c23d61;
  border: 1px solid #c23d61;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  margin-right: 20px;
}
.pop-label {
  font-family: "HiraginoPron W6";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #181924;
}
.col-pd {
  padding-top: 56px;
  padding-bottom: 56px;
}
.col-logo {
  padding-top: 56px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 707px;
    margin: 1.75rem auto;
  }
  .delete-modal {
    max-width: 484px;
    margin: 1.75rem auto;
  }
}
@media only screen and (max-width: 575px) {
  .three-form-bg {
    padding: 88px 50px;
  }

  .forgot-password {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .border-label {
    border-right: none;
    border-bottom: 4px solid #ccd8e3;
    height: auto;
    text-align: left;
    margin-bottom: 10px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .application-center {
    padding: 15px;
  }

  .right-col9 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .border-right {
    border-bottom: 1px solid #dee2e6 !important;
    border-right: transparent !important;
    margin-bottom: 10px;
  }
  .col-right {
    text-align: left;
    margin-top: 15px;
  }
  .col-pd {
    width: 50%;
  }
  .col-logo {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .table-w {
    width: 1500px;
  }
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: absolute; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;

  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 7em;
  max-width: 7em;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  white-space: pre-line;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 99999; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}
pre {
  display: block;
  font-size: 14px;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック",
    "MS Gothic", "Noto Sans CJK JP", TakaoPGothic, sans-serif;
  width: 100%;
  height: 100%;
  padding: 0;
}

.confirm-register-overflow {
  max-width: 350px;
}

.overflow-hidden-confirm {
  overflow: hidden;
}

.tool-tip-div{
  width: 100px;
  height: 10px;

}
.tool-tip-div-icon{
  position: absolute;
}

.zoom-message{
  font-weight: 100;
}

.style-bold{
  font-weight: bold;
}

.pencil-icon{
  width:17px;
  cursor:pointer
}


/************************************************NEW CSS ***********************************************************/


a:hover {
  text-decoration: none;
}

button:focus {
  outline: none !important;
}

* {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.flex-fill {
  flex: 1 1 auto;
}

.rounded-lg {
  border-radius: 1rem;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.form-group {
  margin-bottom: 0px;
}

/****login start****/

.forgotpw {
  color: #1fb9df;
}

.forgotpw:hover {
  color: #059bc0;
  text-decoration: none;
}

.login_center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.three-login-bg {
  background-color: #F0F5FA;
}

.three-form-login {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.three-form-bg {
  background-color: #fff;
  padding: 88px 140px;
  border-radius: 5px;
  border-top: 8px solid #003C73;
  box-shadow: 0px 2px 6px rgba(0, 60, 115, 0.15);
}

.three-form-login h1 {
  color: #181924;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: 'HiraginoPron W6';

}

.padding-top {
  padding-top: 48px;
}

.form-group {
  text-align: start !important;
}

.three-form-login .form-label {
  text-align: start !important;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #181924;
  margin-bottom: 1px;
}

.three-form-login .form-control {
  padding: 8px 16px;
  gap: 8px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #CDCDD6;
  border-radius: 5px;
  color: #6E6F7C;
  font-size: 16px;

}

.mg-top {
  margin-top: 24px;
}

.link-button {
  padding: 8px 16px;
  gap: 8px;
  height: 37px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #191D6A;
  font-size: 14px;
  font-family: 'Hiragino Kaku Gothic Pro';
}

.link-button:hover {
  background-color: #E7E9F3;
}

.common-button {
  padding: 6px 16px;
  gap: 8px;
  width: auto;
  height: 37px;
  background: #191D6A;
  border-radius: 5px;
  color: #fff;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  cursor: pointer;
  border: none;
}

.common-button:hover {
  background-color: #2E388C;
}
.common-button:disabled {
  opacity: 0.5;  /* Fades the button */
  cursor: not-allowed;  /* Changes the cursor to show it's not clickable */
}

/****login end****/

/****reset password***/

.reset-p {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #181924;
  padding-top: 20px;
}

.success-alert {
  background: #EFF8F3;
  border-radius: 5px;
  padding: 10px 16px;
  gap: 10px;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #2B7347;
  text-align: start;
  margin-top: 12px;
}

.form-sub-text {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #4F5062;
  margin-top: 4px;
}

.error-alert {
  background: #FBEFEF;
  border-radius: 5px;
  padding: 10px 16px;
  gap: 10px;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #C23D61;
  text-align: start;
  margin-top: 12px;
}

.icon-alert {
  margin-top: -5px;
}

/***reset end***/

/**** application form start *****/

.form-margin {
  margin-top: 38px;
  margin-bottom: 38px;
}

.three-form-application {
  width: 100%;
  max-width: 861px;
  margin: auto;
}

.three-form-application h1 {
  color: #181924;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: 'HiraginoPron W6';

}

.three-form-application.form-label {
  text-align: start !important;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #181924;
  margin-bottom: 1px;
}

.three-form-application.form-control {
  padding: 8px 16px;
  gap: 8px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #CDCDD6;
  border-radius: 5px;
  color: #6E6F7C;
  font-size: 16px;

}

.border-label {
  border-right: 4px solid #CCD8E3;
  height: auto;
  text-align: right;

}

.right-col9 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.lb-fm {
  font-family: 'HiraginoPron W6';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  text-align: right;
  color: #003C73;
}

.gap-form {
  margin-top: 32px;
}

.font-16 {
  font-size: 16px;
}

.pd-font {
  font-family: 'HiraginoPron W6';
  color: #181924;
}

.border-form {
  border: 1px solid #CDCDD6;
  padding: 32px;
}

.padding-btm {
  padding-bottom: 48px;
}

.pd-24 {
  padding-top: 24px;
}

/*****application form end**/

/*****table start****/
.heading1 {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #866941;
  margin-bottom: 4px;
}

.heading2 {
  font-family: 'HiraginoPron W6';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #181924;
}

.col-margin {
  padding: 0px 14px 56px 14px;
}

/***table end***/


.header-bg {
  background: #F8F8FA;
  border-bottom: 1px solid #E7E9F3;
  padding: 0px 28px;
  margin-left: 55px;
}
.header-enterprise{
  background: #F8F8FA;
  border-bottom: 1px solid #E7E9F3;
  padding: 0px 28px;
}
.container-padding {
  padding-left: 5.5rem;
  padding-right: 28px;
}

.question {
  margin-top: 28px;
}

.pt-21 {
  padding: 21px 0px;
}

.col-search{
  width: 100%;
  max-width: 300px;
} 
.btn-search {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 24px;
  top: 8px;

}

.search-ft {
  padding: 8px 16px;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #CDCDD6;
  border-radius: 5px;
}

.search-ft:focus-visible {
  outline: none;
}

.table {
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0px !important;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #181924;
}

.table-border {
  border: 1px solid #E4E4EA;
  border-radius: 5px;

}

.thead-backg {
  background: #F5F4F4;
  font-family: 'HiraginoPron W6';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4F5062;
  border-bottom: 1px solid #94959F;
}

.table-text {
  font-family: 'HiraginoPron W6';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;
  color: #262E80;
  cursor: pointer;

}

.table thead th {
  border-bottom: 1px solid #94959F;
  border-top: transparent !important;
}

.page-a {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 20px !important;
  margin-left: 12px;
  border: none;
  text-align: center;
  padding-top: 11px;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #181924;
}

.page-a:hover {
  background: #E7E9F3;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #181924;
}

.page-item.active .page-a {
  background: #FFFFFF !important;
  border: 1px solid #C3C7E2 !important;
  border-radius: 20px;
  font-family: 'HiraginoPron W6';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #191D6A;
}

.page-a:focus {
  outline: none;
  box-shadow: none;
}
.left-arrow{
  padding-left: 8px;
}
.right-arrow{
  padding-left: 14px;
}
.pop-head {
  height: 41px;
  background: #F5F4F4;
  position: relative;
}
.close{
  position: absolute;
  right: 9px;
  top: 5px;
  font-size: 28px;
}
.pop-title{
  font-family: 'HiraginoPron W6';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #181924;
padding: 10px 16px;
}
.pop-pg{
  font-family: 'Hiragino Kaku Gothic Pro';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;
color: #181924;
}
.pd-pop{
  padding: 32px;
}
.pop-button{
  font-family: 'Hiragino Kaku Gothic Pro';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 21px;
color: #191D6A;
padding: 8px 16px;
background: #FFFFFF;
border: 1px solid #191D6A;
border-radius: 5px;
}
.pop-button:hover{
  background: #E7E9F3;
  border: 1px solid #191D6A;
}
.exel-bg{
  background: #F8F8FA;
border: 1px solid #9BA3CF;
border-radius: 5px;
margin: 20px 0px;
height: 279px;
justify-content: center;
align-items: center;
display: flex;
}

.centrel-txt{
  font-family: 'HiraginoPron W6';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #191D6A;
}
.super-sub{
  font-family: 'Hiragino Kaku Gothic Pro';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 16px;
color: #4F5062;
}
.heading3{
  font-family: 'Hiragino Kaku Gothic Pro';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 30px;
color: #181924;
}
.border-right{
  border-right: 1px solid #CDCDD6;
}
.col-margin2{
  padding-bottom: 39px;
}
.button-header{
  margin-top: -16px;
  margin-bottom: 16px;
}
.col-right{
  text-align: right;
}
.delete-btn{
  width: 60px;
  height: 37px;
  background: #C23D61;
  border: 1px solid #C23D61;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 14px;
  margin-right: 20px;
  }
  .pop-label{
    font-family: 'HiraginoPron W6';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #181924;
  }
  .col-pd{
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .col-logo{
    padding-top: 56px;
  }
.fnt16{
	font-size: 16px;
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 707px;
    margin: 1.75rem auto;
}
.delete-modal {
  max-width: 484px;
  margin: 1.75rem auto;
}
}
@media only screen and (max-width: 575px) {
  .three-form-bg {
    padding: 88px 50px;
  }

  .forgot-password {
    height: auto;
  }
}

@media only screen and (max-width:767px) {
  .border-label {
    border-right: none;
    border-bottom: 4px solid #CCD8E3;
    height: auto;
    text-align: left;
    margin-bottom: 10px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .application-center {
    padding: 15px;
  }

  .right-col9 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .border-right {
    border-bottom: 1px solid #dee2e6!important;
    border-right: transparent !important;
    margin-bottom: 10px;
  }
  .col-right{
    text-align: left;
    margin-top: 15px;
  }
  .col-pd{
   width: 50%;
  }
  .col-logo{
    width: 50%;
   }
}
@media only screen and (max-width:991px) {
.table-w{
  width: 1500px;
}

}

/* START TOOLTIP STYLES */
[tooltip] {
  position: absolute; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;

  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 8em;
  max-width: 8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

.custom-style{
  background:#e7e9f3;
  color: #000000;
}

.custom-style:hover {
  background-color: #e3e4ec;
}
